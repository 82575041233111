import request from '@/utils/request'

/**
 *
 * @param {正式用户} data
 */
// 正式用户-员工列表 经销商下拉
export function formalUserList(data) {
  return request({
    url: '/superAdmin/dealer-real/index',
    method: 'post',
    data,
  })
}
// 地区下拉
export function areaSelectList(data) {
  return request({
    url: '/baseAdmin/common/common-area',
    method: 'post',
    data,
  })
}
// 注册的地区下拉
export function areaSelectList2(data) {
  return request({
    url: '/auth/login/common-area',
    method: 'post',
    data,
  })
}
// 归属下拉
export function guishuSelectList(data) {
  return request({
    url: '/superAdmin/user/index',
    method: 'post',
    data,
  })
}
/**
 * @行业列表下拉
 */
export function hangyeSelectList(data) {
  return request({
    url: '/superAdmin/dealer/get-industry',
    method: 'post',
    data,
  })
}
/**
 * @省市区
 */

export function localSelectList(data) {
  return request({
    url: '/baseAdmin/common/common-area',
    method: 'post',
    data,
  })
}
/**
 * @获取ID获取编号
 */

export function getId(data) {
  return request({
    url: '/superAdmin/dealer/get-no',
    method: 'post',
    data,
  })
}
/**
 * @创建正式员工
 */
export function createFormalUser(data) {
  return request({
    url: '/superAdmin/dealer-real/create',
    method: 'post',
    data,
  })
}
/**
 * @正式用户详情
 */
export function formalUserDetail(data) {
  return request({
    url: '/superAdmin/dealer-real/detail',
    method: 'post',
    data,
  })
}
/**
 * @正式用户-编辑
 */
export function formalUserEdit(data) {
  return request({
    url: '/superAdmin/dealer-real/update',
    method: 'post',
    data,
  })
}
/**
 * @角色下拉
 */
export function roleList(data) {
  return request({
    url: '/setAdmin/role/list',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {@员工转正式} data
 */
export function staffToFormal(data) {
  return request({
    url: '/superAdmin/dealer-real/staff-real',
    method: 'post',
    data,
  })
}

/**
 * @员工封存
 */
export function staffDisable(data) {
  return request({
    url: '/superAdmin/dealer-real/staff-status',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {员工开启} data
 * @returns
 */
export function staffEnable(data) {
  return request({
    url: '/superAdmin/dealer-real/staff-status2',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {@员工延长} data
 */
export function staffProlong(data) {
  return request({
    url: '/superAdmin/dealer-real/staff-renewal',
    method: 'post',
    data,
  })
}

/**
 * @正式员工列表
 */
export function formalUserStaffList(data) {
  return request({
    url: '/superAdmin/dealer-real/staff',
    method: 'post',
    data,
  })
}

/**
 * @正式用户改变状态
 */

export function formalUserChangeStatus(data) {
  return request({
    url: '/superAdmin/dealer-real/status',
    method: 'post',
    data,
  })
}
/**
 * @正式用户账密
 */

export function formalUserAccount(data) {
  return request({
    url: '/superAdmin/dealer-real/pwd',
    method: 'post',
    data,
  })
}

/**
 * @用户续费数据
 */
export function userRenewalData(data) {
  return request({
    url: '/superAdmin/dealer-real/renew-page',
    method: 'post',
    data,
  })
}
/**
 * @用户续费提交
 */
export function userRenewalSubmit(data) {
  return request({
    url: '/superAdmin/dealer-real/renew',
    method: 'post',
    data,
  })
}
/**
 * @客情详情
 */
export function custDetailData(data) {
  return request({
    url: '/superAdmin/dealer-real/sent-list',
    method: 'post',
    data,
  })
}
/**
 * @提交客情
 */
export function custDetailSubmit(data) {
  return request({
    url: '/superAdmin/dealer-real/sent-add',
    method: 'post',
    data,
  })
}

/**
 * @订单管理页面
 */
/**
 * @订单下拉
 * @类型来源归属
 */

export function orderSelectList(data) {
  return request({
    url: '/superAdmin/order/option',
    method: 'post',
    data,
  })
}
/**
 * @订单表格
 */
export function orderTableData(data) {
  return request({
    url: '/superAdmin/order/index',
    method: 'post',
    data,
  })
}
/**
 * @查看订单
 */
export function checkOrder(data) {
  return request({
    url: '/superAdmin/order/view',
    method: 'post',
    data,
  })
}
/**
 * @订单退款
 */
export function orderReturn(data) {
  return request({
    url: '/superAdmin/order/return',
    method: 'post',
    data,
  })
}

/**
 * @添加合同订单
 */
export function addOrder(data) {
  return request({
    url: '/superAdmin/order/create',
    method: 'post',
    data,
  })
}

/**
 * @订单作废
 */
export function deleteOrder(data) {
  return request({
    url: '/superAdmin/order/out',
    method: 'post',
    data,
  })
}

/**
 * @订单审核详情
 */
export function reviewOrderDetail(data) {
  return request({
    url: '/superAdmin/order/info',
    method: 'post',
    data,
  })
}
/**
 * @审核订单
 */
export function reviewOrder(data) {
  return request({
    url: '/superAdmin/order/check',
    method: 'post',
    data,
  })
}

/**
 * @param {注册用户接口}
 */
/**
 * @客情详情
 */
export function registerCustDetailData(data) {
  return request({
    url: '/superAdmin/dealer/sent-list',
    method: 'post',
    data,
  })
}
/**
 * @客情提交
 */
export function registerCustDetailSubmit(data) {
  return request({
    url: '/superAdmin/dealer/sent-add',
    method: 'post',
    data,
  })
}

/**
 * @z注册用户账密
 */
export function registerUserAccount(data) {
  return request({
    url: '/superAdmin/dealer/pwd',
    method: 'post',
    data,
  })
}
/**
 * @注册用户详情
 */
export function registerDetail(data) {
  return request({
    url: '/superAdmin/dealer/detail',
    method: 'post',
    data,
  })
}
/**
 * @注册用户签约
 */
export function registerSigning(data) {
  return request({
    url: '/superAdmin/dealer/sign',
    method: 'post',
    data,
  })
}
/**
 * @注册用户封存
 */
export function registerStaffStop(data) {
  return request({
    url: '/superAdmin/dealer/staff-status',
    method: 'post',
    data,
  })
}
